import React, { Component, useEffect } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import MainPageHeader from "../components/mainHeader"
import { useState } from "react"
import USAMap from "react-usa-map"
import "./App.css"
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import firebase from 'gatsby-plugin-firebase';
import MediaQuery from 'react-responsive';
import VoteInfo from '../helper/VoteInfo';
import LoadingScreen from 'react-loading-screen';
import ReactLoading from 'react-loading';
import { AiOutlineTwitter, AiOutlineFacebook, AiOutlineMail } from 'react-icons/ai';
const repColor = "#FF4747"
const demColor = "#5793FA"
const defaultState = {
  AL: { fill: repColor },
  AK: { fill: repColor },
  AZ: { fill: repColor },
  AR: { fill: repColor },
  FL: { fill: repColor },
  GA: { fill: repColor },
  ID: { fill: repColor },
  IN: { fill: repColor },
  IA: { fill: repColor },
  KS: { fill: repColor },
  KY: { fill: repColor },
  LA: { fill: repColor },
  MI: { fill: repColor },
  MS: { fill: repColor },
  MO: { fill: repColor },
  MT: { fill: repColor },
  NE: { fill: repColor },
  NC: { fill: repColor },
  ND: { fill: repColor },
  OH: { fill: repColor },
  OK: { fill: repColor },
  PA: { fill: repColor },
  SC: { fill: repColor },
  SD: { fill: repColor },
  TN: { fill: repColor },
  TX: { fill: repColor },
  UT: { fill: repColor },
  WV: { fill: repColor },
  WI: { fill: repColor },
  WY: { fill: repColor },
}


export default class TheMapPage extends Component {

  constructor(props) {
    super(props)
    const defaultState = {
      AL: { fill: repColor },
      AK: { fill: repColor },
      AZ: { fill: repColor },
      AR: { fill: repColor },
      FL: { fill: repColor },
      GA: { fill: repColor },
      ID: { fill: repColor },
      IN: { fill: repColor },
      IA: { fill: repColor },
      KS: { fill: repColor },
      KY: { fill: repColor },
      LA: { fill: repColor },
      MI: { fill: repColor },
      MS: { fill: repColor },
      MO: { fill: repColor },
      MT: { fill: repColor },
      NE: { fill: repColor },
      NC: { fill: repColor },
      ND: { fill: repColor },
      OH: { fill: repColor },
      OK: { fill: repColor },
      PA: { fill: repColor },
      SC: { fill: repColor },
      SD: { fill: repColor },
      TN: { fill: repColor },
      TX: { fill: repColor },
      UT: { fill: repColor },
      WV: { fill: repColor },
      WI: { fill: repColor },
      WY: { fill: repColor },
    }

    this.statesNames = [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",

      "California",
      "Colorado",
      "Connecticut",
      "D.C.",

      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",

      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",

      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",

      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",

      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",

      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",

      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",

      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",

      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",

      "Utah",
      "Vermont",
      "Virginia",
      "Washington",

      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ];
    this.statesAbbrev = [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      "CO",
      'CT',
      'DC',

      'DE',
      'FL',
      'GA',
      'HI',

      'ID',
      'IL',
      'IN',
      'LA',

      'KS',
      'KY',
      'LA',
      'ME',

      'MD',
      'MA',
      'MI',
      'MN',

      'MS',
      'MO',
      'MT',
      'NE',

      'NV',
      'NH',
      'NJ',
      'NM',

      'NY',
      'NC',
      'ND',
      'OH',

      'OK',
      'OR',
      'PA',
      'RI',

      'SC',
      'SD',
      'TN',
      'TX',

      'UT',
      'VT',
      'VA',
      'WA',

      'WV',
      'WI',
      'WY'
    ];
    this.statesFlips = [
      591546,
      36173,
      10457,
      336715,

      5103821,
      439745,
      366114,
      298737,

      95665,
      371686,
      11779,
      169266,

      267098,
      1025024,
      487103,
      138611,

      201083,
      554172,
      399742,
      74335,

      1068609,
      1215000,
      154188,
      233012,

      217281,
      465632,
      98816,
      182263,

      33596,
      59267,
      725061,
      99720,

      1992889,
      74483,
      120693,
      475669,

      516390,
      381935,
      199922,
      107564,

      293562,
      110572,
      708764,
      631221,

      304858,
      130116,
      451138,
      784961,

      309398,
      20681,
      120068,
    ];
    this.state = {
      prevState: defaultState,
      statesCustomConfig: defaultState,
      updatedState: null,
      counter: 0,
      voterState: "",
      democratState: false,
      flipAmount: 0,
      multiplier: 1,
      firstState: "",
      secondState: "",
      show_overlay: false,
      show_overlay_menu: false,
      loading: true,

    }
    this.mapHandler = this.mapHandler.bind(this)
    this.compareStates = this.compareStates.bind(this)
    this.loadElectionInfo();

  }

  componentDidMount(){
     firebase.analytics().logEvent('viewed_voting_tool');
  }
  async loadElectionInfo() {
    console.log('updating.. load election info...');
    let newFlipArray = [];
    this.setState({ loading: true });
    try {
      let stateIdx = 0;
      let newConfig = {};

      await this.asyncForEach(this.statesNames, async state => {
        let electionInfo = await VoteInfo.getElectionInfo(state);
        if (electionInfo.data) {
          let candidates = electionInfo.data.data.races[0].candidates;
          var breakException = {};

          let votesToRepublican = 0;
          let votesToDemocrat = 0;

          
          try {
            candidates.forEach(candidate => {
              if (candidate.party_id == 'republican') votesToRepublican = candidate.votes;
              if (candidate.party_id == 'democrat') votesToDemocrat = candidate.votes;
              if (votesToRepublican !== 0 && votesToDemocrat !== 0) throw breakException;
            });
          } catch (e) {

          }          
          let votesOnFlip = votesToDemocrat - votesToRepublican;
          if (votesOnFlip < 0) {
            //fill with the reppublican color
            let state_rep = this.statesAbbrev[stateIdx];
            newConfig[state_rep] = { fill: repColor };
          }
          newFlipArray.push(votesOnFlip);
          stateIdx++;
          //console.log('state:', state, 'Republican vote:', votesToRepublican, ': Democrat Votes:', votesToDemocrat);
        }
      });
      this.statesFlips = newFlipArray;
      this.updatedState = { ...newConfig };
      this.setState({ statesCustomConfig: newConfig, prevState: newConfig, updatedState: newConfig });
      this.setState({ loading: false });
      console.log('updated State:', this.updatedState);
      //console.log('FlipArray:', newFlipArray,':new config:', newConfig);
    }
    catch (e) {
      //console.log('election error:',e);
      this.setState({ loading: false });
    }

  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  mapHandler(event) {
    if (this.state.counter < 2) {
      this.state.counter++
      const state = event.target.dataset.name
      const config = this.state.prevState

      if (this.state.counter == 1) {
        config[state] = { fill: "green" }
        this.setState({ firstState: state })
      }
      if (this.state.counter == 2) {
        config[state] = { fill: "orange" }
        this.setState({ secondState: state })
      }

      this.prevState = this.state.statesCustomConfig

      this.setState({ statesCustomConfig: config })
    } else {
      this.setState({
        statesCustomConfig: this.updatedState ? { ...this.updatedState } : { ...defaultState },
        prevState: this.updatedState ? { ...this.updatedState } : { ...defaultState },
        counter: 0,
      })
      this.setState({ firstState: "", secondState: "" })
    }
  }

  compareStates(event, homeState, collegeState) {
    console.log('homeState=>', homeState,':collegState=>', collegeState);;
    if (this.state.firstState != "" && this.state.secondState != "") {
      var statesAbbreviations = [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ]

      var homeStateIndex = statesAbbreviations.indexOf(homeState)
      var collegeStateIndex = statesAbbreviations.indexOf(collegeState)

      var stateParty = [
        "R",
        "R",
        "R",
        "R",
        "D",
        "D",
        "D",
        "D",
        "R",
        "R",
        "R",
        "D",
        "R",
        "D",
        "R",
        "R",
        "R",
        "R",
        "R",
        "D", //maine not wta
        "D",
        "D",
        "R",
        "D",
        "R",
        "R",
        "R",
        "R", //nebraska not wta
        "D",
        "D",
        "D",
        "D",
        "D",
        "R",
        "R",
        "R",
        "R",
        "D",
        "R",
        "D",
        "R",
        "R",
        "R",
        "R",
        "R",
        "D",
        "D",
        "D",
        "R",
        "R",
        "R"
      ]
      var homeStateFlip = this.statesFlips[homeStateIndex]
      var collegeStateFlip = this.statesFlips[collegeStateIndex]

      console.log('homestate:', homeStateFlip, ':collegeStateFlip:', collegeStateFlip);
      var statesLinks = [
        "https://www.sos.alabama.gov/alabama-votes/absentee-ballot-applications",
        "https://absenteeballotapplication.alaska.gov/",
        "https://my.arizona.vote/Early/ApplicationLogin.aspx",
        "https://www.sos.arkansas.gov/elections/voter-information/absentee-voting",
        "https://registertovote.ca.gov/",
        "https://www.sos.state.co.us/voter/pages/pub/home.xhtml",
        "https://portal.ct.gov/SOTS/Election-Services/Voter-Information/Absentee-Voting",
        "https://dcboe.org/",
        "https://elections.delaware.gov/voter/absenteeballot.shtml",
        "https://dos.myflorida.com/elections/for-voters/voting/vote-by-mail/",
        "https://sos.ga.gov/index.php/Elections/absentee_voting_in_georgia",
        "https://olvr.hawaii.gov/",
        "https://idahovotes.gov/absentee-voter-information/",
        "https://www.elections.il.gov/ElectionOperations/VotingByMailAgreement.aspx?T=637368651137936480",
        "https://indianavoters.in.gov/",
        "https://sos.iowa.gov/elections/electioninfo/absenteeinfo.html",
        "https://www.kdor.ks.gov/apps/voterreg/default.aspx",
        "https://vrsws.sos.ky.gov/abrweb/",
        "https://www.sos.la.gov/ElectionsAndVoting/Vote/VoteByMail/Pages/default.aspx",
        "https://apps.web.maine.gov/cgi-bin/online/AbsenteeBallot/index.pl",
        "https://voterservices.elections.maryland.gov/OnlineVoterRegistration/InstructionsStep1",
        "https://www.sec.state.ma.us/ele/eleev/early-voting-by-mail.htm",
        "https://mvic.sos.state.mi.us/",
        "https://mnvotes.sos.state.mn.us/ABRegistration/ABRegistrationStep1.aspx",
        "https://www.sos.ms.gov/Vote/Pages/default.aspx",
        "https://www.sos.mo.gov/elections/govotemissouri/howtovote#Absentee",
        "https://sosmt.gov/elections/absentee/",
        "https://sos.nebraska.gov/elections/early-voting-0",
        "https://www.nvsos.gov/sos/elections/voters/absentee-voting",
        "https://sos.nh.gov/elections/voters/absentee-ballots/request-an-absentee-ballot/",
        "https://www.state.nj.us/state/elections/vote-by-mail.shtml",
        "https://www.sos.state.nm.us/voting-and-elections/voter-information-portal/",
        "https://absenteeballot.elections.ny.gov/",
        "https://votebymail.ncsbe.gov/app/home",
        "https://vip.sos.nd.gov/PortalListDetails.aspx?ptlhPKID=51&ptlPKID=7",
        "https://www.sos.state.oh.us/elections/voters/absentee-voting/#byMail",
        "https://www.ok.gov/elections/OVP.html",
        "https://sos.oregon.gov/voting/Pages/voteinor.aspx",
        "https://www.votespa.com/Voting-in-PA/Pages/Mail-and-Absentee-Ballot.aspx",
        "https://vote.sos.ri.gov/Voter/VotebyMail?ActiveFlag=4",
        "https://info.scvotes.sc.gov/eng/voterinquiry/VoterInformationRequest.aspx?PageMode=AbsenteeRequest",
        "https://sdsos.gov/elections-voting/voting/absentee-voting.aspx",
        "https://www.govotetn.com/",
        "https://vrapp.sos.state.tx.us/index.asp",
        "https://vote.utah.gov/",
        "https://sos.vermont.gov/elections/voters/early-absentee-voting/",
        "https://vote.elections.virginia.gov/VoterInformation",
        "https://voter.votewa.gov/WhereToVote.aspx",
        "https://sites.omniballot.us/54/absentee/app/home",
        "https://myvote.wi.gov/en-us/",
        "https://sos.wyo.gov/Elections/State/AbsenteeVoting.aspx",
      ]
      var homeStateName = this.statesNames[homeStateIndex]
      var collegeStateName = this.statesNames[collegeStateIndex]
      var votesPerElectorate = ["235000", "105000", "242727", "187833", "247273", "300000", "230000", "98500", "147667", "330345", "260938", "109400", "173250", "270000", "248273", "262500", "191667", "243750", "253750", "185000", "258000", "295727", "300000", "294600", "201500", "280000", "170000", "167000", "187500", "183000", "257857", "160000", "247414", "315000", "115000", "313889", "207857", "287143", "298250", '115500', "233889", "123667", "227273", "235526", "154167", "107000", "305385", "266667", "145800", "293500", "86333"];
      var homeStateElectorate = votesPerElectorate[homeStateIndex];
      var collegeStateElectorate = votesPerElectorate[collegeStateIndex];
      /*
      if (homeStateElectorate < collegeStateElectorate) {
        var voterStateTemp = homeStateName;
        var flipAmountTemp = homeStateFlip;
        var multiplierTemp = collegeStateElectorate / homeStateElectorate;
      }
      else if (homeStateElectorate > collegeStateElectorate) {
        var voterStateTemp = collegeStateName;
        var flipAmountTemp = collegeStateFlip;
        var multiplierTemp = homeStateElectorate / collegeStateElectorate;
      } else {
        var voterStateTemp = homeStateName;
        var flipAmountTemp = homeStateFlip;
        var multiplierTemp = 1
      } */
      const absHomeStateFlip = Math.abs(homeStateFlip);
      const absCollegStateFlip = Math.abs(collegeStateFlip);

      
      if (absHomeStateFlip < absCollegStateFlip) {
        var voterStateTemp = homeStateName
        var flipAmountTemp = absHomeStateFlip
        var multiplierTemp = absCollegStateFlip / absHomeStateFlip
      } else if (absHomeStateFlip > absCollegStateFlip) {
        var voterStateTemp = collegeStateName
        var flipAmountTemp = absCollegStateFlip
        var multiplierTemp = absHomeStateFlip / absCollegStateFlip
      } else {
        var voterStateTemp = homeStateName;
        var flipAmountTemp = absHomeStateFlip;
        var multiplierTemp = 1;
      }

      
      var voteIndex = this.statesNames.indexOf(voterStateTemp);
      var voteLink = statesLinks[voteIndex];
      const democratStateTemp = stateParty[voteIndex] === "D";

      flipAmountTemp = flipAmountTemp.toLocaleString()
      multiplierTemp = multiplierTemp.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
      console.log('flipAmountTemp:',flipAmountTemp);
      this.setState({
        voterLink: voteLink,
        voterState: voterStateTemp,
        democratState: democratStateTemp,
        flipAmount: flipAmountTemp,
        multiplier: multiplierTemp,
      })
      this.setState({ show_overlay: true })

      this.serverFunction(homeState, collegeState);
    }
  }


  serverFunction(stateHome, stateCollege) {
    //var newBody = "homeState=" + stateHome + "&collegeState=" + stateCollege;
    ////For Production:
    //const API_URL = "https://us-central1-sf2020-bd929.cloudfunctions.net/usage";
    ////For Dev:     
    ////const API_URL = "https://us-central1-sf2020-devel.cloudfunctions.net/usage";
    //fetch(API_URL, {
    //  body: newBody,
    //  headers: {
    //    "Content-Type": "application/x-www-form-urlencoded"
    //  },
    //  method: "POST"
    //}).then(response => console.log(response.status)); //only keep this for testing, remove all console.log() for prod
  }

  render() {
    // console.log('loading..', this.state.loading);
    return (
      <div>

        <SEO title="Vote" />
        <MediaQuery maxDeviceWidth={1224} orientation={"portrait"}>
          <div>

            <div style={{ position: 'absolute', width: "100vw" }}>
              <div class="mobile-headerContainer">
                <Link to="https://www.turnup.us" style={{ textDecoration: 'none' }}>
                  <div className={'header-logo'}>
                    <img alt="menu-button" class={'mobile-headerIcon'} src={require('../images/turnup_logo.png')}/>
                  </div>
                </Link>
              </div>
              <div class="mobile-main-bodyContainer">               
                <text class="mobile-main-titleText">College Students<br />Can Decide The<br />2022 Election.</text>
                <text class="mobile-main-subtitleText">Changing where you vote<br />can increase you impact<br />up to 9x as much</text>
                {/* <div class="mobile-main-videoPlaceholder">
                  <iframe title="Students for 2020"  src="https://www.youtube.com/embed/QlxRKqTQJOg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                </div> */}
              </div>
              <div class="mobile-map-bodyContainer">
                <div style={{ marginLeft: "18vw" }}>
                  <text class="mobile-map-titleText">
                    Just fill out some info and<br />
                    our Algorithms will do the<br />
                    rest!

                  </text>
                </div>
                <div class="mobile-map-stepCircle" style={{ marginTop: "17vh" }}>
                  <text class="mobile-map-stepCircleText">1</text>
                </div>
                <div class="mobile-map-stepCircle" style={{ marginTop: "23vh" }}>
                  <text class="mobile-map-stepCircleText">2</text>
                </div>
                <div class="mobile-map-stepCircle" style={{ marginTop: "29vh" }}>
                  <text class="mobile-map-stepCircleText">3</text>
                </div>

                <text class="mobile-map-stepText" style={{ marginTop: "17.24vh" }}>Click on your home state</text>
                <text class="mobile-map-stepText" style={{ marginTop: "23.28vh" }}>Click on your college state</text>
                <text class="mobile-map-stepText" style={{ marginTop: "29.19vh" }}>Click "where should I vote?"</text>

                <div class="mobile-map-legendBoxGreen" style={{ marginLeft: "6.67vw" }} />
                <text class="mobile-map-legendText" style={{ marginLeft: "13.33vw" }}>Green = Home</text>
                <div class="mobile-map-legendBoxOrange" style={{ marginLeft: "48.87vw" }} />
                <text class="mobile-map-legendText" style={{ marginLeft: "56.07vw" }}>Orange = College</text>

                <div class="mobile-map-mapPlaceholder">
                  <USAMap height="24.63vh" width="84.27vw" defaultFill={demColor} customize={this.state.statesCustomConfig} onClick={this.mapHandler} />
                </div>

                <a onClick={(event) => this.compareStates(event, this.state.firstState, this.state.secondState)}>
                  <div class="mobile-map-submitButton">
                    <text class="mobile-map-submitButtonText">Where should I vote?</text>
                  </div>
                </a>
              </div>

              <div class="mobile-mapPopup-bodyContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
                <div class="mobile-mapPopup-mainContainer">
                  <text class="mobile-mapPopup-titleTextOne">
                    You should register to vote in:<br />
                    {this.state.voterState}
                  </text>
                  <text class="mobile-mapPopup-bodyText" style={{ fontSize: "0.97rem" }}>
                    ➼ The election is tighter in <text style={{ color: "#FF4747" }}>{this.state.voterState}</text>.<br /><br />
                    <div>➼ The margin of the Election is <text style={{ color: "#FF4747" }}>{this.state.flipAmount} votes.<br /><br /></text></div>
                    <div>➼ Because of the closer race, your vote will be <text style={{ color: "#FF4747" }}>{this.state.multiplier > 0 ? this.state.multiplier : -this.state.multiplier}x more impactful</text>.</div>
                  </text>


                  <div class="mobile-mapPopup-button" style={{ backgroundColor: '#4766FF', marginTop: "32.15vh" }}>
                    <Link to={this.state.voterLink} style={{ textDecoration: "none" }}>
                      <text class="mobile-mapPopup-buttonText" >Register to vote at this location</text>
                    </Link>
                  </div>


                  <div class="mobile-mapPopup-divider" />

                  {/*<text class="mobile-mapPopup-titleTextTwo"  style={{fontSize:"1rem"}}>
                    Our country needs more<br />
                  students like you.
                </text>*/}

                  <text class="mobile-mapPopup-bodyTextTwo">
                    Can you <a href='https://www.instagram.com/p/CFvYslDg3LB/?igshid=nty7msv8c4y6'><u>repost this on
                      Instagram</u></a> and tag us to
                    help us recruit more young
                    people to save the Election?
                  </text>
                  <div class="mobile-mapPopup-bottomButton" >
                    <a href="#" onClick={event => this.setState({ show_overlay: false })}>

                      <text class="mobile-mapPopup-bottomButtonText">Done!</text>

                    </a>
                  </div>



                </div>
              </div>

              <div class="mobile-footerContainer" style={{ marginTop: '84.6vh' }}>
                <div className={'mobile-bottomLogo'}>
                  <img alt="menu-button" class={'mobile-bottomImage'} src={require('../images/turnup_logo.png')}/>
                </div>
                <text class="mobile-footerSmallText">@2022 by TurnUp</text>
                <text class="mobile-footerTextContact">Contact</text>
                <a class={'mobile-mailLink'} href="mailto:info@turnup.us?subject=Hey TurnUp! Question for you!" target="_self"><span class={'mobile-mailto'}>info@turnup.us</span></a>
              </div>
            </div>
            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay_menu === true ? 'block' : 'none' }}>

              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay_menu: false })} style={{ display: this.state.show_overlay_menu === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224} maxDeviceHeight={700} orientation={"portrait"}>
          <div>
            <div style={{ position: 'absolute', width: "100vw" }}>
              <div class="mobile-headerContainer">
                <Link to="https://www.turnup.us" style={{ textDecoration: 'none' }}>
                  <div className={'header-logo'}>
                    <img alt="menu-button" class={'mobile-headerIcon'} src={require('../images/turnup_logo.png')}/>
                  </div>
                </Link>
              </div>
              <div class="mobile-main-bodyContainer">
                <text class="mobile-main-titleText">College Students<br />Can Decide The<br />2022 Election.</text>
                <text class="mobile-main-subtitleText">Changing where you vote<br />can increase you impact<br />up to 9x as much</text>
                {/* <div class="mobile-main-videoPlaceholder">
                  <iframe title="Students for 2020"  src="https://www.youtube.com/embed/QlxRKqTQJOg"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
                </div> */}
              </div>  
              <div class="mobile-map-bodyContainer">
                <div style={{ marginLeft: "18vw" }}>
                  <text class="mobile-map-titleText">
                    Just fill out some info and<br />
                    our Algorithms will do the<br />
                    rest!

                  </text>
                </div>
                <div class="mobile-map-stepCircle" style={{ marginTop: "16.5vh" }}>
                  <text class="mobile-map-stepCircleText">1</text>
                </div>
                <div class="mobile-map-stepCircle" style={{ marginTop: "22.5vh" }}>
                  <text class="mobile-map-stepCircleText">2</text>
                </div>
                <div class="mobile-map-stepCircle" style={{ marginTop: "28.5vh" }}>
                  <text class="mobile-map-stepCircleText">3</text>
                </div>

                <text class="mobile-map-stepText" style={{ marginTop: "17.24vh" }}>Click on your home state</text>
                <text class="mobile-map-stepText" style={{ marginTop: "23.28vh" }}>Click on your college state</text>
                <text class="mobile-map-stepText" style={{ marginTop: "29.19vh" }}>Click "where should I vote?"</text>

                <div class="mobile-map-legendBoxGreen" style={{ marginLeft: "8.67vw" }} />
                <text class="mobile-map-legendText" style={{ marginLeft: "15.33vw", fontSize: "0.9rem" }}>Green = Home</text>
                <div class="mobile-map-legendBoxOrange" style={{ marginLeft: "50vw" }} />
                <text class="mobile-map-legendText" style={{ marginLeft: "57.07vw", fontSize: "0.9rem" }}>Orange = College</text>

                <div class="mobile-map-mapPlaceholder">
                  <USAMap height="24.63vh" width="84.27vw" defaultFill={demColor} customize={this.state.statesCustomConfig} onClick={this.mapHandler} />
                </div>

                <a onClick={(event) => this.compareStates(event, this.state.firstState, this.state.secondState)}>
                  <div class="mobile-map-submitButton" style={{ width: "80%" }}>
                    <text class="mobile-map-submitButtonText">Where should I vote?</text>
                  </div>
                </a>
              </div>

              <div class="mobile-mapPopup-bodyContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
                <div class="mobile-mapPopup-mainContainer">
                  <text class="mobile-mapPopup-titleTextOne">
                    You should register to vote in:<br />
                    {this.state.voterState}
                  </text>
                  <text class="mobile-mapPopup-bodyText">
                    ➼ The election is tighter in <text style={{ color: "#FF4747" }}>{this.state.voterState}</text>.<br /><br />
                    ➼ The margin of the Election is <text style={{ color: "#FF4747" }}>{this.state.flipAmount} votes.<br /><br /></text>
                    ➼ Because of the closer race, your vote will be  <text style={{ color: "#FF4747" }}>{this.state.multiplier}x more impactful</text>.
                  </text>


                  <div class="mobile-mapPopup-button" style={{ backgroundColor: '#4766FF', marginTop: "32.15vh" }}>
                    <Link to={this.state.voterLink} style={{ textDecoration: "none" }}>
                      <text class="mobile-mapPopup-buttonText" >Register to vote at this location</text>
                    </Link>
                  </div>

                  <div class="mobile-mapPopup-divider" />

                  {/*<text class="mobile-mapPopup-titleTextTwo" style={{fontSize:"1rem"}}>
                    Our country needs more<br />
                  students like you.
              </text>*/}

                  <text class="mobile-mapPopup-bodyTextTwo" style={{ marginTop: "49vh" }}>
                    Can you <a href='https://www.instagram.com/p/CFvYslDg3LB/?igshid=nty7msv8c4y6'><u>repost this on
                      Instagram</u></a> and tag us to
                    help us recruit more young
                    people to save the Election?
                  </text>
                  <div class="mobile-mapPopup-bottomButton" style={{ marginRight: "0vw" }}>
                    <a href="#" onClick={event => this.setState({ show_overlay: false })}>

                      <text class="mobile-mapPopup-bottomButtonText">Done!</text>

                    </a>
                  </div>


                </div>
              </div>

              <div class="mobile-footerContainer" style={{ marginTop: '84.6vh' }}>
                <div className={'mobile-bottomLogo'}>
                  <img alt="menu-button" class={'mobile-bottomImage'} src={require('../images/turnup_logo.png')}/>
                </div>
                <text class="mobile-footerSmallText">@2022 by TurnUp</text>
                <text class="mobile-footerTextContact">Contact</text>
                <a class={'mobile-mailLink'} href="mailto:info@turnup.us?subject=Hey TurnUp! Question for you!" target="_self"><span class={'mobile-mailto'}>info@turnup.us</span></a>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay_menu === true ? 'block' : 'none' }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay_menu: false })} style={{ display: this.state.show_overlay_menu === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1399} minDeviceWidth={1244}>
          <div style={{ position: 'absolute', width: "100vw" }}>
            <div class="map-HeaderContainer">
              <div class="topBar">
                <div class="logoDiv">
                   <Link to="https://www.turnup.us" style={{ textDecoration: 'none' }}>
                    <div className={'header-logo'}>
                      <img alt="menu-button" class={'headerImage'} src={require('../images/turnup_logo.png')}  />
                    </div>
                  </Link>
                </div>
                <div class="headerDiv">
                </div>
                <div class="headButtDiv">
                </div>
              </div>
            </div>
            <MainPageHeader>
              <div class="upperSectionCover">
                <div class="upperSectionData">
                  <text class="upperTitle">
                    College Students Can
                    <br />
                    Decide the 2022 Election.
                  </text>
                  <text class="upperParagraph">
                  Young people will decide the Midterms. 
                  Where you vote this year matters. 
                  We’ll show you where your vote will make the most difference.
                  </text>
                </div>
              </div>
            </MainPageHeader>
            <div class="mapBody">

              <div class="mapStepWrapper">
                <div class="map-StepDiv">
                  <div class="map-StepCircle" >
                    <text class="map-StepCircleText">1</text>
                  </div>
                  <div>
                    <text class="map-StepTitleText">
                      Click on your home state
                    </text>
                  </div>
                </div>
                <div class="map-StepDiv">
                  <div class="map-StepCircle" >
                    <text class="map-StepCircleText">2</text>
                  </div>
                  <div>
                    <text class="map-StepTitleText">
                      Click on your school state
                    </text>
                  </div>
                </div>
                <div class="map-StepDiv">
                  <div class="map-StepCircle" >
                    <text class="map-StepCircleText">3</text>
                  </div>
                  <div>
                    <text class="map-StepTitleText">
                      Click "Where should I vote?"
                    </text>
                  </div>
                </div>
              </div>

              <div class="mapBoxes">
                <div class="map-HighlightColorBoxGreen" style={{ marginLeft: "7.2vw" }} />
                <div class="map-HighlightColorBoxOrange" style={{ marginLeft: "18vw" }} />
                <text class="map-HighlightColorText" style={{ marginLeft: "8.7vw" }}>
                  Green = Home
                </text>
                <text class="map-HighlightColorText" style={{ marginLeft: "19.6vw" }}>
                  Orange = College
                </text>
              </div>
              <div class="mapInteractive">
                <USAMap
                  defaultFill={demColor}
                  customize={this.state.statesCustomConfig}
                  onClick={this.mapHandler}
                  height="55vh"
                  width="50vw"
                />

              </div>

              <a
                onClick={event =>
                  this.compareStates(
                    event,
                    this.state.firstState,
                    this.state.secondState
                  )
                }
              >
                <div class="map-WhereVoteButton">
                  <text class="map-WhereVoteButtonText">
                    Where should I vote?
                  </text>
                </div>
              </a>
              <div
                class="popup-darken"
                style={{
                  display: this.state.show_overlay === true ? "block" : "none",
                }} /*onClick={(event) => this.setState({show_overlay: false})}*/
              >
                <div class="popup-container">
                  <div style={{ textAlign: "center", marginLeft: "2w" }}>
                    <text class="popup-TitleText" style={{ marginLeft: "5.2vw", marginTop: "7vh" }}>
                      You should register to vote in: {this.state.voterState}
                    </text>
                  </div>
                  <div style={{ marginTop: "7.6vh", marginLeft: "5.3vw" }}>
                    <text class="popup-InfoText" style={{ fontSize: "1.1rem", marginRight: "4vw" }}>
                      ➼ The election is tighter in <text style={{ color: "#FF4747" }}>{this.state.voterState}</text>.<br /><br />
                      <div>➼ The margin of the Election is <text style={{ color: "#FF4747" }}>{this.state.flipAmount} votes.<br /><br /></text></div>
                      <div>➼ Because of the closer race, your vote will be <text style={{ color: "#FF4747" }}>{this.state.multiplier > 0 ? this.state.multiplier : -this.state.multiplier}x more impactful</text>.</div>
                    </text>
                  </div>




                  <a href={this.state.voterLink}>
                    <div class="popup-VoteButton" style={{ marginTop: "45vh", marginLeft: "3vw", backgroundColor: "#4766FF" }}>
                      <text class="popup-VoteButtonText" style={{ marginTop: 10.5, marginLeft: 33, fontSize: "1.1rem" }}>
                        Register to vote at this location
                      </text>
                    </div>
                  </a>
                  <text class="popup-TitleText" style={{ marginTop: "55vh", marginLeft: "12vw", fontSize: "1.2vw" }}>
                    Make your vote more impactful
                  </text>
                  <TwitterShareButton style={{ backgroundColor: "#47afff", marginLeft: '8vw', marginTop: '60vh', height: '5.22vh', width: '8vw', position: "absolute", borderRadius: '10px' }} title="Check out this tool that enables you to figure out where you vote makes a bigger difference." url="https://vote.turnup.us">
                    <div>
                      <AiOutlineTwitter className="social-icon" />
                      <text class="popup-ShareButtonText">Twitter</text>
                    </div>
                  </TwitterShareButton>
                  <FacebookShareButton style={{ backgroundColor: "#4766FF", marginLeft: '18vw', marginTop: '60vh', height: '5.22vh', width: '8vw', position: "absolute", borderRadius: '10px' }} quote="Check out this tool that enables you to figure out where you vote makes a bigger difference." hashtag="#smashmouth" url="https://vote.turnup.us">
                    <div>
                      <AiOutlineFacebook className={'social-icon'} />
                      <text class="popup-ShareButtonText">Facebook</text>
                    </div>
                  </FacebookShareButton>
                  <EmailShareButton style={{ backgroundColor: "#ff4747", marginLeft: '28vw', marginTop: '60vh', height: '5.22vh', width: '8vw', position: "absolute", borderRadius: '10px' }} subject="Vote tool" body="Check out this tool that enables you to figure out where you vote makes a bigger difference. https://vote.turnup.us">
                    <div>
                      <AiOutlineMail className={'social-icon'} />
                      <text class="popup-ShareButtonText">Email</text>
                    </div>
                  </EmailShareButton>
                  <div class="closebutton" style={{ marginLeft: "37vw" }} >
                    <a href="#" onClick={event => this.setState({ show_overlay: false })}>
                      <img src={require('../images/close_icon.svg')} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottomBar"  >
              <a href="/" style={{ textDecoration: 'none' }}>
                <div className={'bottom-logo'}>
                  <img alt="menu-button" src={require('../images/turnup_logo.png')} class="mobile-headerButton" />
                </div>
              </a>
              <div class="rightsReservedDiv">
                <text class="rightsReserved">@2022  by TurnUp</text>
              </div>
              <div class="contactUsDiv">
                <div className="labelBottom">Contact</div>
                <a href="mailto:info@turnup.us?subject=Hey TurnUp! Question for you!" target="_self"><span>info@turnup.us</span></a>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1400}>
          <div style={{ position: "relative" }}>
            <div class="map-HeaderContainer">
              <div class="topBar">
                <div class="logoDiv">
                  <Link to="https://www.turnup.us" style={{ textDecoration: 'none' }}>
                    <div className={'header-logo'}>
                      <img alt="menu-button" class={'headerImage'} src={require('../images/turnup_logo.png')}  />
                    </div>
                  </Link>
                </div>
                <div class="headerDiv">
                </div>
                <div class="headButtDiv">
                </div>
              </div>
            </div>
            <MainPageHeader>
              <div class="upperSectionCover">
                <div class="upperSectionData">
                  <text class="upperTitle">
                    College Students Can
                    <br />
                    Decide the 2022 Election.
                  </text>
                  <text class="upperParagraph">
                  Young people will decide the Midterms. 
                  Where you vote this year matters. 
                  We’ll show you where your vote will make the most difference.
                  </text>
                </div>
              </div>
            </MainPageHeader>
            <div class="mapBody">
              {/* {
                this.state.loading &&
                <div className={"loading-bar"}>
                  <ReactLoading type={'bubbles'} color={'#5793FA'} height={64} width={64} />
                  <p>Loading Live Data</p>
                </div>
              } */}
              <div class="mapStepWrapper">
                <div class="map-StepDiv">
                  <div class="map-StepCircle" >
                    <text class="map-StepCircleText">1</text>
                  </div>
                  <div>
                    <text class="map-StepTitleText">
                      Click on your home state
                    </text>
                  </div>
                </div>
                <div class="map-StepDiv">
                  <div class="map-StepCircle" >
                    <text class="map-StepCircleText">2</text>
                  </div>
                  <div>
                    <text class="map-StepTitleText">
                      Click on your school state
                    </text>
                  </div>
                </div>
                <div class="map-StepDiv">
                  <div class="map-StepCircle" >
                    <text class="map-StepCircleText">3</text>
                  </div>
                  <div>
                    <text class="map-StepTitleText">
                      Click "Where should I vote?"
                    </text>
                  </div>
                </div>
              </div>

              <div class="mapBoxes">
                <div class="map-HighlightColorBoxGreen" style={{ marginLeft: "7.2vw" }} />
                <div class="map-HighlightColorBoxOrange" style={{ marginLeft: "18vw" }} />
                <text class="map-HighlightColorText" style={{ marginLeft: "8.7vw" }}>
                  Green = Home
                </text>
                <text class="map-HighlightColorText" style={{ marginLeft: "19.6vw" }}>
                  Orange = College
                </text>
              </div>
              <div class="mapInteractive">
                <USAMap
                  defaultFill={demColor}
                  customize={this.state.statesCustomConfig}
                  onClick={this.mapHandler}
                  height="55vh"
                  width="50vw"
                />
              </div>

              <a
                onClick={event =>
                  this.compareStates(
                    event,
                    this.state.firstState,
                    this.state.secondState
                  )
                }
              >
                <div class="map-WhereVoteButton">
                  <text class="map-WhereVoteButtonText">
                    Where should I vote?
                  </text>
                </div>
              </a>
              <div
                class="popup-darken"
                style={{
                  display: this.state.show_overlay === true ? "block" : "none",
                }} /*onClick={(event) => this.setState({show_overlay: false})}*/
              >
                <div class="popup-container">
                  <div style={{ textAlign: "center", marginLeft: "2w" }}>
                    <text class="popup-TitleText" style={{ marginLeft: "5.2vw", marginTop: "7vh" }}>
                      You should register to vote in: {this.state.voterState}
                    </text>
                  </div>
                  <div style={{ marginTop: "4.6vh", marginLeft: "8vw" }}>
                    <text class="popup-InfoText">
                      ➼ The election is tighter in
                      <text style={{ color: "#FF4747" }}> {this.state.voterState}</text>.<br /><br />
                      <div>➼ The margin of the Election is <text style={{ color: "#FF4747" }}>{this.state.flipAmount} votes.<br /><br /></text></div>
                      ➼ Because of the closer race, your vote will be<br />
                      <text style={{ color: "#FF4747" }}>{this.state.multiplier > 0 ? this.state.multiplier : -this.state.multiplier}x more impactful</text>.
                    </text>
                  </div>




                  <a href={this.state.voterLink}>
                    <div class="popup-VoteButton" style={{ marginTop: "43vh", backgroundColor: "#4766FF" }}>
                      <text class="popup-VoteButtonText" style={{ marginTop: 13, marginLeft: 45 }}>
                        Register to vote at this location
                      </text>
                    </div>
                  </a>
                  <text class="popup-TitleText" style={{ marginTop: "55vh", marginLeft: "12vw", fontSize: "1.2vw" }}>
                    Make your vote more impactful
                  </text>
                  <TwitterShareButton style={{ backgroundColor: "#47afff", marginLeft: '8vw', marginTop: '60vh', height: '5.22vh', width: '8vw', position: "absolute", borderRadius: '10px' }} title="Check out this tool that enables you to figure out where you vote makes a bigger difference." url="https://vote.turnup.us">
                    <div className={'icon-wrapper'}>
                      <AiOutlineTwitter className="social-icon" />
                      <text class="popup-ShareButtonText">Twitter</text>
                    </div>
                  </TwitterShareButton>
                  <FacebookShareButton style={{ backgroundColor: "#4766FF", marginLeft: '18vw', marginTop: '60vh', height: '5.22vh', width: '8vw', position: "absolute", borderRadius: '10px' }} quote="Check out this tool that enables you to figure out where you vote makes a bigger difference." hashtag="#smashmouth" url="https://vote.turnup.us/">
                    <div className={'icon-wrapper'}>
                      <AiOutlineFacebook className={'social-icon'} />
                      <text class="popup-ShareButtonText">Facebook</text>
                    </div>
                  </FacebookShareButton>
                  <EmailShareButton style={{ backgroundColor: "#ff4747", marginLeft: '28vw', marginTop: '60vh', height: '5.22vh', width: '8vw', position: "absolute", borderRadius: '10px' }} subject="Vote tool" body="Check out this tool that enables you to figure out where you vote makes a bigger difference. https://vote.turnup.us">
                    <div className={'icon-wrapper'}>
                      <AiOutlineMail className={'social-icon'} />
                      <text class="popup-ShareButtonText">Email</text>
                    </div>
                  </EmailShareButton>
                  <div class="closebutton"  >
                    <a href="#" onClick={event => this.setState({ show_overlay: false })}>
                      <img src={require('../images/close_icon.svg')} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottomBar"  >
              <a href="/" style={{ textDecoration: 'none' }}>
                <div className={'bottom-logo'}>
                  <img alt="menu-button" src={require('../images/turnup_logo.png')} class="mobile-headerButton" />
                </div>
              </a>
              <div class="rightsReservedDiv">
                <text class="rightsReserved">@2022 by TurnUp</text>
                <div class="contactUsDiv">
                  <div className="labelBottom">Contact</div>
                  <a href="mailto:info@turnup.us?subject=Hey TurnUp! Question for you!" target="_self"><span>info@turnup.us</span></a>
                </div>
              </div>

            </div>
          </div>
        </MediaQuery>


      </div>
    );
  }
}
