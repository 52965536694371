import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../pages/App.css"

const MainPageHeader= ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "mainHeader.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  const imageData = desktop.childImageSharp.fluid
  return (
      <BackgroundImage Tag="section" className={className} fluid={imageData}>
        {children}
      </BackgroundImage>
  )
}


export default MainPageHeader;
