import Axios from 'axios';

const VoteInfo = {

    getElectionInfo : (state) => {
        let statePrefix;
        if(state == "D.C.") statePrefix = "district-of-columbia";
        else{
            statePrefix = state.toLowerCase().replace(" ", "-");
        }

        let url = `https://static01.nyt.com/elections-assets/2020/data/api/2020-11-03/race-page/${statePrefix}/president.json`;
        return Axios.get(url);
    },
    getVotesInfo : () => {
        let url = 'voteinfo.json';
        return Axios.get(url);

    }   
}

export default VoteInfo;